import '@google/model-viewer/dist/model-viewer';
import { Menu, Transition } from '@headlessui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { addToCart, getCart } from '../../api/adapters/cart';
import { CONFIG } from '../../configs/platform.config';
import { updateCart } from '../../redux/actions/cart';
import { notify } from '../../utils/common.helper';
import { getCookie } from '../../utils/cookies.helper';
import { chainSymbolById, toFixedNumber } from '../../utils/helper';

// IMAGES
import moment from 'moment';
import { hideNFT } from '../../api/adapters/nft';
import BSC from '../../assets/images/Binance-icon.svg';
import BOBA from '../../assets/images/Boba-icon.svg';
import EPIKO from '../../assets/images/EPIKO.png';
import ETH from '../../assets/images/Ethereum-icon.svg';
import MATIC from '../../assets/images/Polygon-icon.svg';
import SPIZ from '../../assets/images/SPIZ.png';
import CartIcon from '../../assets/images/cart-icon.svg';
import LikeBlankIcon from '../../assets/images/like-border-icon.svg';
import LikeIcon from '../../assets/images/liked-icon.svg';
import OMI from '../../assets/images/omi-icon.svg';
import USDC from '../../assets/images/usd-icon.svg';
import BidNowPopup from '../Modal/BidNowPopup';
import BurnNftPopup from '../Modal/BurnNftPopup';
import PutNftOnSalePopup from '../Modal/PutNftOnSalePopup';
import RemoveFromSalePopup from '../Modal/RemoveFromSalePopup';
import TransferNFTPopup from '../Modal/TransferNFTPopup';
import Timer from '../Timer/Timer';

function CardMedium(props) {
    const {
        data,
        ManageBid,
        isMyNft,
        mutateUnLike,
        isHidden = false,
        refetch,
        isSlider = false,
        refetchNft,
    } = props;
    const chainIcon = {
        ETH: ETH,
        BNB: BSC,
        MATIC: MATIC,
        USDC: USDC,
        BOBA: BOBA,
        OMI: OMI,
        EPIKO: EPIKO,
        SPIZ: SPIZ,
        "BOBA BNB": BOBA,
    };

    const [isLike, setIsLike] = useState(false);
    const [isInCart, setIsInCart] = useState(false);
    const [displayTime, setDisplayTime] = useState(null);
    const [isRemoveFromSalePopupOpen, setIsRemoveFromSalePopupOpen] =
        useState(false);
    const [isTransferNftPopupOpen, setIsTransferNftPopupOpen] = useState(false);
    const [isPutOnSalePopupOpen, setIsPutOnSalePopupOpen] = useState(false);
    const [isBurnNftPopupOpen, setIsBurnNftPopupOpen] = useState(false);
    const [isBidNowPopupOpen, setIsBidNowPopupOpen] = useState(false);
    const [usdPrice, setUsdPrice] = useState(0);
    const [stopTimer, setStopTimer] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const modelRef = useRef();
    const dispatch = useDispatch();
    const { address } = useAccount();

    const user = useSelector((state) => state.user);
    const cart = useSelector((state) => state.cart);
    const price = useSelector((state) => state.price);

    let eType = data?.eType || data?.aNFT?.eType;

    const { refetch: refetchCart } = useQuery({
        queryKey: ['cartData'],
        queryFn: () =>
            getCart().then((res) => {
                dispatch(updateCart(res.data.data));
                return res.data.data;
            }),
        enabled: false,
        retry: false,
    });

    const { mutate: mutateCart } = useMutation({
        mutationFn: (data) => addToCart(data),
        onSuccess: (data) => {
            notify('success', data.data.message);
            refetchCart();
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const { mutate: mutateHide } = useMutation({
        mutationFn: (status) => hideNFT(data._id, status),
        onSuccess: (data) => {
            notify('success', "NFT's hidden status updated successfully");
            refetch();
            refetchNft?.();
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    useEffect(() => {
        if (data?.aFavorite?.includes(getCookie('_userId'))) {
            setIsLike(true);
        }

        if (data?.dCreatedAt) {
            const parsedTime = moment(data?.dCreatedAt);
            const currentTime = moment();
            let time;

            // Check if the time difference is more than 24 hours
            if (currentTime.diff(parsedTime, 'hours') > 24) {
                time = parsedTime.format('DD/MM/YYYY');
            } else {
                time = parsedTime.fromNow();
            }

            setDisplayTime(time);
        }
    }, [data]);

    useEffect(() => {
        if (cart.length) {
            cart?.forEach((item) => {
                if (item?.iNFT === data?._id) {
                    setIsInCart(true);
                }
            });
        }
    }, [cart]);

    useEffect(() => {
        let cryptoPrice;

        if (data?.aTransactions?.eBidStatus === 'Timed Auction') {
            cryptoPrice = Number(data?.aLatestBid?.nBidPrice || 0);
        } else
            cryptoPrice = Number(
                data?.aTransactions?.nBidPrice ||
                data?.transactions?.nBidPrice ||
                // data?.nBasePrice ||
                data?.nBidPrice ||
                0
            );

        const cryptoSymbol =
            data?.sTokenSymbol ||
            data.aTransactions?.sTokenSymbol ||
            data.transactions?.sTokenSymbol ||
            chainSymbolById(data?.nChainId || data?.aNFT?.nChainId);

        const convertedPrice = cryptoPrice * price?.[cryptoSymbol];

        setUsdPrice(convertedPrice);
    }, [price]);

    useEffect(() => {
        setImageUrl(
            `${data?.bIsMigrated ? CONFIG.sPinataIpfsUri : CONFIG.sFilebaseIpfsUri}${data?.sImageHash || data?.aNFT?.sImageHash}`
        );
    }, [data]);

    const handleError = () => {
        setImageUrl(
            `${CONFIG.sIpfsUri}${data?.sImageHash || data?.aNFT?.sImageHash}`
        );
    };

    return (
        <div className='group relative bg-white h-full rounded-xl border border-dark-20 p-2 duration-300 hover:bg-black hover:border-dark-20 hover:-translate-y-2.5 hover:z-[5]'>
            <Link
                to={`/nft/${data?.aNFT?._id || data?._id}`}
                className='absolute w-full h-full top-0 left-0 z-[2]'
            ></Link>
            <div className='relative border-1 border-dark-10 rounded pt-[86%]'>
                {eType === 'image' ? (
                    <img
                        src={imageUrl}
                        alt={data.title}
                        className='rounded absolute top-0 left-0 w-full h-full object-cover object-center'
                        onError={handleError}
                    />
                ) : eType === 'model' ? (
                    <model-viewer
                        alt={data.title}
                        src={imageUrl}
                        ar
                        autoplay
                        // environment-image='shared-assets/environments/moon_1k.hdr'
                        poster={`${imageUrl}`}
                        shadow-intensity='1'
                        camera-controls={false}
                        ref={(ref) => {
                            modelRef.current = ref;
                        }}
                        touch-action='pan-y'
                        class='rounded absolute top-0 left-0 w-full h-full object-cover object-center'
                        onError={handleError}
                    ></model-viewer>
                ) : eType === 'video' ? (
                    <video
                        className='rounded absolute top-0 left-0 w-full h-full object-cover object-center'
                        src={imageUrl}
                        onError={handleError}
                    />
                ) : null}

                <div className='absolute bottom-8 w-full text-center invisible duration-300 opacity-0 z-[5] group-hover:opacity-100 group-hover:visible'>
                    {data?.aTransactions?.eBidStatus === 'List' &&
                        !isInCart &&
                        !isMyNft &&
                        user.id !== data?.oCurrentOwner && (
                            // address.toLowerCase()
                            <button
                                className='group btn-border border-transparent'
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!user?.id)
                                        return notify(
                                            'error',
                                            'Please connect your wallet'
                                        );

                                    mutateCart({
                                        sNftId: data?._id,
                                        nQuantity: 1,
                                        nChainId: data?.nChainId,
                                        sPaymentMode:
                                            data.aTransactions.sTokenSymbol ||
                                            chainSymbolById(data?.nChainId),
                                    });
                                }}
                            >
                                <img
                                    src={CartIcon}
                                    alt='CartIcon'
                                    className='mr-2 group-[.btn-border:hover]:invert'
                                />
                                ADD TO BAG
                            </button>
                        )}

                    {(data?.aTransactions?.eBidStatus === 'Timed Auction' ||
                        data?.transactions?.eBidStatus === 'Timed Auction') &&
                        (data?.aTransactions?.nEndTime > moment().unix() ||
                            data?.transactions?.nEndTime > moment().unix()) &&
                        user.id !== data?.oCurrentOwner && (
                            <button
                                className='group btn-border border-transparent'
                                onClick={() => {
                                    if (!address)
                                        return notify(
                                            'error',
                                            'Please connect your wallet'
                                        );
                                    setIsBidNowPopupOpen(true);
                                }}
                            >
                                <img
                                    src={CartIcon}
                                    alt='CartIcon'
                                    className='mr-2 group-[.btn-border:hover]:invert'
                                />
                                BID NOW
                            </button>
                        )}
                    {data.bid_status === 'Pending' && (
                        <button className='group btn-border border-transparent'>
                            WITHDRAW
                        </button>
                    )}
                </div>
                {mutateUnLike && (
                    <button
                        className='absolute z-[3] right-4 top-4 inline-flex items-center'
                        onClick={() => {
                            setIsLike(!data.isLiked);
                            mutateUnLike(data._id);
                        }}
                    >
                        <span className='bg-black/70 rounded-full mr-2 p-2'>
                            <img
                                src={isLike ? LikeIcon : LikeBlankIcon}
                                alt='LikeIcon'
                                className='w-6'
                            />
                        </span>
                    </button>
                )}
                {(data?.aTransactions?.eBidStatus === 'Timed Auction' ||
                    data?.transactions?.eBidStatus === 'Timed Auction') && (
                        <Timer
                            epochTime={
                                data?.aTransactions?.nEndTime ||
                                data?.transactions?.nEndTime
                            }
                            setStopTimer={setStopTimer}
                        />
                    )}
                {ManageBid && (
                    <div className='absolute right-4 top-4 flex items-center text-base text-white rounded-lg bg-black/60 backdrop-blur-md py-1.5 px-3 lg:text-sm'>
                        {data?.sCancelStatus === 0 ? 'Pending' : 'Claimed'}
                    </div>
                )}
            </div>
            <div className='px-4 py-6 sm:py-4 flex justify-between'>
                <div>
                    <h6 className='text-lg lg:text-md inline-block text-black font-bold group-hover:bg-gradient group-hover:text-transparent group-hover:bg-clip-text'>
                        {data.sName || `You Placed Bid on ${data.aNFT.sName}`}
                    </h6>
                    {isSlider && (
                        <div className='flex items-center mt-2 group-hover:text-dark-40'>
                            {data.aUser[0].sProfilePicUrl ? (
                                <img
                                    src={data.aUser[0].sProfilePicUrl}
                                    alt='RegalLogo'
                                    className='rounded-full w-9 h-9 object-cover object-center mr-2'
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                        e.target.style.display = 'block'; // Adjust as needed
                                        e.target.src =
                                            'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                    }}
                                />
                            ) : (
                                <div className='rounded-full w-9 h-9 object-cover object-center mr-2 bg-gradient'></div>
                            )}
                            @{data.aUser?.[0].sUserName}
                        </div>
                    )}
                    {data?.aCollection?.sName && (
                        <div className='flex items-center mt-2 text-dark-70 font-bold group-hover:text-dark-40'>
                            {data.aCollection.sName}
                        </div>
                    )}
                    {data?.dCreatedAt && (
                        <p className='text-dark-70 font-bold group-hover:text-white'>
                            {displayTime}
                        </p>
                    )}
                    {data.aUser?.sProfilePicUrl && data.aUser.sUserName && (
                        <div className='flex items-center mt-2 text-dark-70 group-hover:text-dark-40 bg-gradient'>
                            {
                                <img
                                    src={data.aUser.sProfilePicUrl}
                                    alt={data.aUser.sUserName}
                                    className='rounded-full w-9 h-9 object-cover object-center mr-2'
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                        e.target.style.display = 'block'; // Adjust as needed
                                        e.target.src =
                                            'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                    }}
                                />
                            }
                            {data.aUser.sUserName}
                        </div>
                    )}
                </div>
                {isMyNft &&
                    user.id === data.oCurrentOwner &&
                    (
                        <Menu
                            as='div'
                            className='relative inline-block text-left z-10'
                        >
                            <div>
                                <Menu.Button className='inline-flex w-12 h-12 justify-center rounded-full bg-dark-10 items-center gap-1 group-hover:bg-darkGray xl:w-10 xl:h-10'>
                                    <span className='w-1.5 h-1.5 rounded-xl bg-black inline-block group-hover:bg-white'></span>
                                    <span className='w-1.5 h-1.5 rounded-xl bg-black inline-block group-hover:bg-white'></span>
                                    <span className='w-1.5 h-1.5 rounded-xl bg-black inline-block group-hover:bg-white'></span>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter='transition ease-out duration-100'
                                enterFrom='transform opacity-0 translate-y-4'
                                enterTo='transform opacity-100 translate-y-2'
                                leave='transition ease-in duration-75'
                                leaveFrom='transform opacity-100 translate-y-2'
                                leaveTo='transform opacity-0 translate-y-4'
                            >
                                <Menu.Items className='dropdown right-0 w-60'>
                                    {!isHidden ? (
                                        <>
                                            {data?.aTransactions?.eBidStatus ===
                                                'List' ||
                                                data?.aTransactions?.eBidStatus ===
                                                'Timed Auction' ? (
                                                <>
                                                    {!data?.aLatestBid?.eBidStatus && <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                                onClick={(e) =>
                                                                    setIsRemoveFromSalePopupOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Remove From Sale
                                                            </button>
                                                        )}
                                                    </Menu.Item>}
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                                onClick={() =>
                                                                    setIsTransferNftPopupOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Transfer NFT
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                                onClick={() =>
                                                                    setIsBurnNftPopupOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Burn NFT
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            ) : (
                                                <>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                                onClick={() =>
                                                                    setIsPutOnSalePopupOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Put On Sale
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                                onClick={() =>
                                                                    setIsTransferNftPopupOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                Transfer NFT
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    {/* <Menu.Item>
                                                    {({ active }) => (
                                                        <button className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'>
                                                            Burn NFT
                                                        </button>
                                                    )}
                                                </Menu.Item> */}
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    mutateHide({
                                                                        bHideStatus: true,
                                                                    });
                                                                }}
                                                            >
                                                                Hide NFT
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className='font-medium text-black text-base px-10 py-4 w-full text-left block duration-300 hover:bg-primary/20'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        mutateHide({
                                                            bHideStatus: false,
                                                        });
                                                    }}
                                                >
                                                    Unhide
                                                </button>
                                            )}
                                        </Menu.Item>
                                    )}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    )}
            </div>
            {data?.aTransactions?.eBidStatus === 'Timed Auction' ||
                data?.aTransactions?.eBidStatus === 'On Auction' ? (
                <>
                    <div className='border-t flex justify-between items-center border-dark-30 px-7 py-2 -mx-2.5  sm:py-3'>
                        <p className='text-sm text-dark-70 duration-300 group-hover:text-dark-40'>
                            Base Price
                        </p>
                        <h6 className='text-base text-black font-bold duration-300 flex items-center group-hover:text-white'>
                            <img
                                src={
                                    chainIcon[
                                    chainSymbolById(data?.nChainId) ||
                                    data.aTransactions?.sTokenSymbol ||
                                    data.transactions?.sTokenSymbol
                                    ]
                                }
                                alt='OmiIcon'
                                className='w-6 h-6 object-cover object-center mr-2'
                            />
                            {toFixedNumber(data?.aTransactions?.nBidPrice ||
                                data?.nBasePrice ||
                                data?.nBidPrice ||
                                data?.transactions?.nBidPrice || 0, 18)
                            }{' '}
                            {data.aTransactions?.sTokenSymbol ||
                                data.transactions?.sTokenSymbol ||
                                chainSymbolById(data?.nChainId)}
                        </h6>
                    </div>
                    <div className='border-t border-dark-30 px-7 py-2 pt-3 -mx-2.5  sm:py-1.5'>
                        <div className='flex justify-between'>
                            <div>
                                <h6 className='text-2xl text-black font-bold duration-300 flex items-center group-hover:text-white 2xl:text-xl md:text-md'>
                                    Highest Bid
                                </h6>
                            </div>
                            <div className='text-right'>
                                <h6 className='text-2xl text-black font-bold duration-300 flex items-center group-hover:text-white 2xl:text-xl md:text-md'>
                                    {toFixedNumber(data?.aLatestBid?.nBidPrice || 0, 18)}{' '}
                                    {data.aTransactions?.sTokenSymbol ||
                                        data.transactions?.sTokenSymbol ||
                                        chainSymbolById(data?.nChainId)}
                                </h6>
                                <p className='text-sm text-dark-70 font-medium duration-300 group-hover:text-dark-40'>
                                    ~{usdPrice.toFixed(2)} USD
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='border-t border-dark-30 px-7 py-4 -mx-2.5  sm:py-3'>
                    {!ManageBid && (
                        <p className='text-sm text-dark-70 duration-300 group-hover:text-dark-40'>
                            Base Price
                        </p>
                    )}
                    <div className='flex justify-between pt-3 '>
                        <h6 className='text-base text-black font-bold duration-300 flex items-center group-hover:text-white'>
                            <img
                                src={
                                    chainIcon[
                                    chainSymbolById(
                                        data?.nChainId ||
                                        data?.aNFT?.nChainId
                                    ) ||
                                    data.sTokenSymbol ||
                                    data.aTransactions?.sTokenSymbol ||
                                    data.transactions?.sTokenSymbol
                                    ]
                                }
                                alt='chainLogo'
                                className='w-6 h-6 object-cover object-center mr-2'
                            />
                            {toFixedNumber(data?.aTransactions?.nBidPrice ||
                                // data?.nBasePrice ||
                                data?.nBidPrice ||
                                data?.transactions?.nBidPrice ||
                                0, 18)}{' '}
                            {
                                chainSymbolById(
                                    data?.nChainId || data?.aNFT?.nChainId
                                ) === "BOBA" ? "ETH" : chainSymbolById(
                                    data?.nChainId || data?.aNFT?.nChainId
                                ) ||
                                data?.sTokenSymbol ||
                                data.aTransactions?.sTokenSymbol ||
                                data?.transactions?.sTokenSymbol
                            }
                        </h6>

                        <p className='text-sm text-dark-70 font-medium duration-300 group-hover:text-dark-40'>
                            ~{usdPrice.toFixed(2)} USD
                        </p>
                    </div>
                </div>
            )}
            <TransferNFTPopup
                isPopupOpen={isTransferNftPopupOpen}
                setIsPopupOpen={setIsTransferNftPopupOpen}
                nftId={data?.nTokenId}
                nftChainId={data?.nChainId}
                refetchNft={refetch}
                nftType={data?.sCollectionType}
                nftQuantity={data?.nQuantity}
                nftAddress={data?.sCollectionAddress}
            />

            {isBurnNftPopupOpen && (
                <BurnNftPopup
                    isPopupOpen={isBurnNftPopupOpen}
                    setIsPopupOpen={setIsBurnNftPopupOpen}
                    nftQuantity={data?.nQuantity}
                    nftAddress={data?.sCollectionAddress}
                    nftId={data?.nTokenId}
                    nftChainId={data?.nChainId}
                    nftType={data?.sCollectionType}
                    owner={data?.oCurrentOwner?.sWalletAddress}
                />
            )}
            <PutNftOnSalePopup
                isPopupOpen={isPutOnSalePopupOpen}
                setIsPopupOpen={setIsPutOnSalePopupOpen}
                nftQuantity={data?.nQuantity}
                nftAddress={data?.sCollectionAddress}
                nftId={data?.nTokenId}
                nftChainId={data?.nChainId}
                refetchNft={refetch}
                nftType={data?.sCollectionType}
                owner={data?.aCurrentOwner?.sWalletAddress}
            />
            <RemoveFromSalePopup
                isPopupOpen={isRemoveFromSalePopupOpen}
                setIsPopupOpen={setIsRemoveFromSalePopupOpen}
                nftAddress={data?.sCollectionAddress}
                nftId={data?.nTokenId}
                nftChainId={data?.nChainId}
                refetchNft={refetch}
                method={
                    data?.aTransactions?.eBidStatus === 'List'
                        ? 'withdrawSale'
                        : 'withdrawAuction'
                }
            />
            {isBidNowPopupOpen && (
                <BidNowPopup
                    isPopupOpen={isBidNowPopupOpen}
                    setIsPopupOpen={setIsBidNowPopupOpen}
                    currentOwner={
                        data?.aCurrentOwner?.sWalletAddress ||
                        data?.user?.sWalletAddress ||
                        data?.aUser?.[0]?.sWalletAddress
                    }
                    nftQuantity={data?.nQuantity}
                    nftAddress={data?.sCollectionAddress}
                    nftId={data?.nTokenId}
                    nftChainId={data?.nChainId}
                    nftImage={imageUrl}
                    nftName={data?.sName}
                    nftPrice={data?.aLatestBid?.nBidPrice || data?.nBasePrice}
                    sTokenSymbol={
                        data.aTransactions?.sTokenSymbol ||
                        data.transactions?.sTokenSymbol
                    }
                    sTokenAddress={
                        data.aTransactions?.sTokenAddress ||
                        data?.transactions?.sTokenAddress
                    }
                    eType={data?.eType}
                    refetchNft={refetch}
                // refetchActivity={refetchActivity}
                // refetchBidActivity={refetchBidActivity}
                />
            )}
        </div>
    );
}

export default CardMedium;
