import '@google/model-viewer/dist/model-viewer';
import React, { useEffect, useRef, useState } from 'react';

// IMAGES
import FileuploadIcon from '../../assets/images/fileupload-icon.svg';

function FileUpload(props) {
    const {
        label,
        onChange,
        id,
        selectedFile,
        fileType,
        error,
        sImageType,
        currentFile,
        supportedFileTypes,
    } = props;
    const [preview, setPreview] = useState();
    const videoRef = useRef();

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        // Check if selectedFile is a File or Blob
        if (!(selectedFile instanceof Blob)) {
            console.error('Selected file is not a Blob or File');
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // Free memory when the component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);


    useEffect(() => {
        videoRef.current?.load();
    }, [selectedFile]);

    return (
        <div className=''>
            <label
                htmlFor={id}
                className='block text-sm font-semibold text-dark-60'
            >
                {label}
                <span className='text-red-600 ml-0.5'>*</span>
            </label>
            <div className='border-2 mt-2 border-dashed border-dark-40 rounded-xl relative text-center p-5 flex flex-col justify-center items-center min-h-48 gap-4'>
                <input
                    type='file'
                    onChange={onChange}
                    accept={supportedFileTypes}
                    id={id}
                    className='absolute left-0 top-0 w-full h-full opacity-0'
                />
                {preview || currentFile ? (
                    <>
                        {sImageType === 'video' ? (
                            <div className='w-full max-w-sm mx-auto border rounded-xl p-1 relative z-20'>
                                <video
                                    controls
                                    className='aspect-video w-full'
                                    ref={videoRef}
                                >
                                    <source
                                        src={preview || currentFile}
                                        type='video/mp4'
                                    />
                                </video>
                            </div>
                        ) : sImageType === 'model' ? (
                            <div className='max-w-sm h-48 w-full mx-auto border rounded-xl p-1 relative z-20'>
                                <div className='absolute top-0 left-0 w-full h-full object-cover object-center'>
                                    <model-viewer
                                        alt={selectedFile?.sName}
                                        src={preview || currentFile}
                                        ar
                                        shadow-intensity='1'
                                        camera-controls
                                        // ref={(ref) => {
                                        //     modelRef.current = ref;
                                        // }}
                                        touch-action='pan-y'
                                        class='w-full h-full'
                                        autoplay
                                    ></model-viewer>
                                </div>
                            </div>
                        ) : (
                            <div className='max-w-sm h-48 mx-auto border rounded-xl p-1 mb-2'>
                                <img
                                    src={preview || currentFile}
                                    alt={selectedFile?.name}
                                    className='object-cover object-center w-full h-full rounded-xl'
                                />
                            </div>
                        )}
                        <div className='text-sm text-primary-30 mb-3 break-all'>
                            {selectedFile?.name}
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <img
                                src={FileuploadIcon}
                                alt='FileuploadIcon'
                                className='me-2'
                            />
                        </div>
                        <button className='btn-border btn-sm'>
                            Browse Files
                        </button>
                        <div>
                            {selectedFile ? (
                                <span className='break-all inline-block'>
                                    {selectedFile?.name}
                                </span>
                            ) : fileType ? (
                                <span className='text-dark-80'>{fileType}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                )}
            </div>
            {error && <span className='text-red-600'>{error}</span>}
        </div>
    );
}

export default FileUpload;
