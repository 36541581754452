import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import FileUpload from '../../components/FormControl/FileUpload';
import Input from '../../components/FormControl/Input';
import RadioButton from '../../components/FormControl/RadioButton';
import SelectDropdown from '../../components/FormControl/SelectDropdown';
import TextArea from '../../components/FormControl/TextArea';

// IMAGES
import InfoIcon from '../../assets/images/info-icon.svg';
import MinusIcon from '../../assets/images/minus-icon.svg';
import PlusIcon from '../../assets/images/plus-icon.svg';
import { errorMessage } from '../../utils/error.message';

import { useMutation, useQuery } from '@tanstack/react-query';
import {
    useAccount,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import { getBrand, getCategory } from '../../api/adapters/asset-common';
import { getCollab } from '../../api/adapters/collaborator';
import { getCollection } from '../../api/adapters/collection';
import { createNft } from '../../api/adapters/createNft';
import ButtonLoader from '../../components/Loader/ButtonLoader';
import { notify } from '../../utils/common.helper';

import AddCollection from '../../components/Modal/collection/AddCollection';
import _contracts_web3 from '../../configs/contracts.web3';
import { toggleLoader } from '../../redux/actions/loader';
import { parseRevertReason } from '../../utils/helper';

function CreateNFT() {
    let formData = new FormData();

    const [inputValue, setInputValue] = useState({
        nftImage: '',
        nftTitle: '',
        brand: '',
        category: '',
        collection: '',
        description: '',
        unlockable: '',
        quantity: '',
        royalty: '',
        sImageType: '',
        propertyTitle: [],
        propertyValue: [],
        collaboratorName: [],
        collaboratorValue: [],
    });
    const [error, setError] = useState({
        nftImage: '',
        nftTitle: '',
        brand: '',
        category: '',
        collection: '',
        description: '',
        quantity: '',
        royalty: '',
        blockChian: '',
        propertyTitle: [],
        propertyValue: [],
        collaboratorName: [],
        collaboratorValue: [],
    });

    const [propertyFields, setPropertyFields] = useState([1]);
    const [collaboratorsFields, setCollaboratorsFields] = useState([1]);
    const [selectedFile, setSelectedFile] = useState();
    const [blockchain, setBlockchain] = useState();
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            addFormData();
            mutateCreateNft(formData);
        },
    });

    const checkIfGLBModel = (filename) => {
        return (filename || '').toLowerCase().endsWith('.glb') ? 'model' : '';
    };

    function fileOnChange(event) {
        if (event.target.files.length > 0) {
            let sImageType = event.target.files[0].type
                ? event.target.files[0].type.split('/')[0]
                : checkIfGLBModel(event.target.files[0]?.name);

            setInputValue((prev) => ({
                ...prev,
                sImageType,
            }));

            setSelectedFile(event.target.files[0]);
            setInputValue((prev) => ({
                ...prev,
                nftImage: event.target.files[0],
            }));
            setError((prev) => ({
                ...prev,
                nftImage: '',
            }));
        }
    }

    const { data: collections } = useQuery({
        queryKey: ['collections'],
        queryFn: () => getCollection().then((res) => res.data.data),
    });

    const { data: collaborators } = useQuery({
        queryKey: ['collaborators'],
        queryFn: () => getCollab().then((res) => res.data.data),
    });

    const { data: categories } = useQuery({
        queryKey: ['categories'],
        queryFn: () => getCategory().then((res) => res.data.data),
    });

    const { data: brand } = useQuery({
        queryKey: ['brand'],
        queryFn: () =>
            getBrand().then((res) => {
                setInputValue((prev) => ({
                    ...prev,
                    brand: res.data.data?.[0]?._id,
                }));
                return res.data.data[0];
            }),
    });

    const { mutate: mutateCreateNft, isPending } = useMutation({
        mutationFn: (data) => createNft(data),
        onSuccess: (data) => {
            const collabAddress = [];
            const collabRoyalty = [];
            inputValue.collaboratorName.forEach((element, index) => {
                collabAddress.push(collaborators[element].sWalletAddress);
                collabRoyalty.push(inputValue.collaboratorValue[index] * 100);
            });
            const nftAddress =
                inputValue.quantity > 1
                    ? _contracts_web3.addresses[blockchain].ERC1155
                    : _contracts_web3.addresses[blockchain].ERC721;

            write({
                args: [
                    nftAddress,
                    'https://ipfs.filebase.io/ipfs/' + data.data.data.sJsonHash,
                    inputValue.quantity,
                    collabAddress,
                    collabRoyalty,
                    inputValue.royalty * 100,
                ],
            });
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong');
        },
    });

    const { data, write, isLoading } = useContractWrite({
        address: _contracts_web3.addresses[blockchain]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'mintToken',
        account: address,
        onError(error) {
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const { isLoading: isLoadingTransaction, isSuccess: isSuccessTransaction } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isSuccessTransaction) {
            notify('success', 'NFT created successfully');
            navigate('/my-nft');
        }
    }, [isSuccessTransaction]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(toggleLoader(false));
        }, 300);
    }, []);

    //Increase property input field
    const increaseProperty = () => {
        setPropertyFields([...propertyFields, 1]);
    };

    //Decrease property input field
    const decreaseProperty = (index) => {
        if (propertyFields.length - 1 === 0) {
            return; // No need to proceed if there's only one property
        }

        const property = [...propertyFields];

        let propertyTitle = inputValue.propertyTitle;
        let propertyValue = inputValue.propertyValue;

        let propertyTitleError = error.propertyTitle;
        let propertyValueError = error.propertyValue;

        // Remove the index that we want to delete
        propertyValue[index] = propertyValue[propertyValue.length - 1];
        propertyTitle[index] = propertyTitle[propertyTitle.length - 1];
        propertyValue.pop();
        propertyTitle.pop();

        // Remove the error of index that we want to delete
        propertyValueError[index] =
            propertyValueError[propertyValueError.length - 1];
        propertyValueError.pop();

        propertyTitleError[index] =
            propertyTitleError[propertyTitleError.length - 1];
        propertyTitleError.pop();

        const lastIndex = propertyFields.length - 1;

        property.splice(lastIndex, 1);

        setInputValue((prev) => ({
            ...prev,
            propertyTitle,
            propertyValue,
        }));
        setError((prev) => ({
            ...prev,
            propertyTitle: propertyTitleError,
            propertyValue: propertyValueError,
        }));

        setPropertyFields(property);
    };

    //Increase collaborators input field
    const increaseCollaborators = () => {
        if (collaborators.length === collaboratorsFields.length) return;
        setCollaboratorsFields([...collaboratorsFields, 1]);
    };

    //Decrease collaborators input field
    const decreaseCollaborators = (index) => {
        if (collaboratorsFields.length - 1 === 0) {
            return;
        }

        const collab = [...collaboratorsFields];
        const collabName = error.collaboratorName;
        const collabValue = error.collaboratorValue;

        let collaboratorName = inputValue.collaboratorName;
        let collaboratorValue = inputValue.collaboratorValue;

        let collaboratorNameError = error.collaboratorName;
        let collaboratorValueError = error.collaboratorValue;

        // Remove the index that we want to delete
        collaboratorName[index] = collaboratorName[collaboratorName.length - 1];
        collaboratorValue[index] =
            collaboratorValue[collaboratorValue.length - 1];

        collaboratorName.pop();
        collaboratorValue.pop();

        // Remove the error of index that we want to delete
        collaboratorNameError[index] =
            collaboratorNameError[collaboratorNameError.length - 1];
        collaboratorNameError.pop();

        collaboratorValueError[index] =
            collaboratorValueError[collaboratorValueError.length - 1];
        collaboratorValueError.pop();

        const lastIndex = collaboratorsFields.length - 1;

        collab.splice(lastIndex, 1);
        collabName.splice(lastIndex, 1);
        collabValue.splice(lastIndex, 1);

        setInputValue((prev) => ({
            ...prev,
            collaboratorName,
            collaboratorValue,
        }));

        setError((prev) => ({
            ...prev,
            collaboratorName: collaboratorNameError,
            collaboratorValue: collaboratorValueError,
        }));

        setCollaboratorsFields(collab);
    };

    const handleSubmit = async () => {
        try {
            await validator();
            await addFormData();

            console.log('blockchain : ', blockchain, "Chain id : ", chain.id);

            if (blockchain !== chain.id) {
                return switchNetwork(blockchain);
            } else {
                mutateCreateNft(formData);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function validator() {
        return new Promise((resolve, reject) => {
            let isError = false;
            let formError = {
                propertyValue: error.propertyValue,
                propertyTitle: error.propertyTitle,
                collaboratorName: error.collaboratorName,
                collaboratorValue: error.collaboratorValue,
            };

            let excludeKey = [
                'unlockable',
                'propertyTitle',
                'propertyValue',
                'collaboratorName',
                'collaboratorValue',
            ];

            if (!blockchain) {
                formError.blockChian = errorMessage.blockChian;
            } else {
                formError.blockChian = '';
            }

            for (const key in inputValue) {
                if (Object.hasOwnProperty.call(inputValue, key)) {
                    const element = inputValue[key];

                    if (excludeKey.includes(key)) {
                        continue;
                    }

                    if (element === 0) {
                        formError[key] = '';
                        continue;
                    }
                    if (!element) {
                        formError[key] = errorMessage[key];
                        isError = true;
                    } else if (key === 'royalty' && element > 100) {
                        formError[key] = "Royalty can't be greater than 100";
                        continue;
                    } else if (key === 'nftTitle' && element.length > 25) {
                        formError[key] =
                            "Title can't be greater than 25 characters";
                        continue;
                    } else if (key === 'description' && element.length > 250) {
                        formError[key] =
                            "Description can't be greater than 250 characters";
                        continue;
                    } else if (key === 'unlockable' && element.length > 250) {
                        formError[key] =
                            "Description can't be greater than 250 characters";
                        continue;
                    } else {
                        isError = false;
                        formError[key] = '';
                    }
                }
            }

            let percentage = 0;
            collaboratorsFields.forEach((ele, index) => {
                const collaboratorName = inputValue.collaboratorName[index];
                const collaboratorValue = inputValue.collaboratorValue[index];

                if (collaboratorsFields.length === 1 && index === 0) {
                    if (collaboratorName && collaboratorValue) {
                        formError.collaboratorName[index] =
                            collaboratorName === 0
                                ? ''
                                : errorMessage.collaboratorName;
                        formError.collaboratorValue[index] = collaboratorValue
                            ? ''
                            : errorMessage.collaboratorValue;

                        if (collaboratorValue > 100) {
                            isError = true;
                            formError.collaboratorValue[index] =
                                "Collaborator Royalty can't be greater than 100";
                        }
                    } else {
                        formError.collaboratorName[index] = '';
                        formError.collaboratorValue[index] = '';
                    }
                } else {
                    formError.collaboratorName[index] =
                        collaboratorName !== index
                            ? errorMessage.collaboratorName
                            : '';
                    isError = collaboratorName !== index;

                    if (!collaboratorValue || collaboratorValue > 100) {
                        isError = true;
                        formError.collaboratorValue[index] = !collaboratorValue
                            ? errorMessage.collaboratorValue
                            : "Collaborator Royalty can't be greater than 100";
                    } else {
                        percentage += +collaboratorValue;
                        formError.collaboratorValue[index] = '';
                    }
                }

                if (percentage > 100) {
                    isError = true;
                    formError.collaboratorValue[index] =
                        'Please adjust royalty distribution to 100%';
                }
            });

            propertyFields.forEach((ele, index) => {
                const propertyTitle = inputValue.propertyTitle[index];
                const propertyValue = inputValue.propertyValue[index];

                if (propertyFields.length === 1 && index === 0) {
                    if (propertyTitle || propertyValue) {
                        formError.propertyTitle[index] = propertyTitle
                            ? ''
                            : errorMessage.propertyTitle;

                        formError.propertyValue[index] = propertyValue
                            ? ''
                            : errorMessage.propertyValue;
                    } else {
                        formError.propertyTitle[index] = '';
                        formError.propertyValue[index] = '';
                    }
                } else {
                    if (!inputValue.propertyTitle[index]) {
                        isError = true;
                        formError.propertyTitle[index] =
                            errorMessage.propertyTitle;
                    } else {
                        isError = false;
                        formError.propertyTitle[index] = '';
                    }

                    if (!inputValue.propertyValue[index]) {
                        isError = true;

                        formError.propertyValue[index] =
                            errorMessage.propertyValue;
                    } else {
                        isError = false;

                        formError.propertyValue[index] = '';
                    }
                }
            });

            const isFormError = Object.values(formError).every((value) => {
                return (
                    value === '' ||
                    (value.length > 0 &&
                        Array.isArray(value) &&
                        value.every((val) => val === ''))
                );
            });

            setError({ ...formError });

            if (!isFormError || isError) {
                reject({ ...formError });
            } else {
                resolve({ ...formError });
            }
        });
    }

    function addFormData() {
        let property = [];
        inputValue.propertyTitle.forEach((element, index) => {
            property.push({
                trait_type: element,
                value: inputValue.propertyValue[index],
            });
        });

        formData.append('nft', inputValue.nftImage);
        formData.append('sName', inputValue.nftTitle);
        formData.append('sBrandId', inputValue.brand);
        formData.append('sCategoryId', categories[inputValue.category]._id);
        formData.append(
            'sCollectionId',
            collections[inputValue.collection]?._id || undefined
        );
        formData.append('sDescription', inputValue.description);
        formData.append('nQuantity', inputValue.quantity);
        formData.append('nCreatorRoyalty', inputValue.royalty * 100);
        formData.append('attributes', JSON.stringify(property));
        formData.append('sImageType', inputValue.sImageType);
        formData.append('sUnlockableContent', inputValue.unlockable);

        // for (const value of formData.values()) {
        //     console.log(value);
        // }
    }

    const blockchainList = process.env.REACT_APP_ENVIRONMENT === "stage" || process.env.REACT_APP_ENVIRONMENT === "production" ? [
        {
            name: 'Ethereum',
            value: 1,
        },
        {
            name: 'BOBA',
            value: 288,
        },
        {
            name: 'Polygon',
            value: 137,
        },
        {
            name: 'zkEVM',
            value: 1101,
        },
        {
            name: 'BNB',
            value: 56,
        },
        {
            name: 'Boba BNB Testnet',
            value: 9728,
        },
        {
            name: 'Basechain',
            value: 84532,
        }
    ] : [
        {
            name: 'Ethereum',
            value: 11155111,
        },
        {
            name: 'BOBA',
            value: 2888,
        },
        {
            name: 'Polygon',
            value: 80001,
        },
        {
            name: 'zkEVM',
            value: 1442,
        },
        {
            name: 'BNB',
            value: 97,
        },
        {
            name: 'Boba BNB Testnet',
            value: 9728,
        },
        {
            name: 'Basechain',
            value: 84532,
        }
    ];

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center'>
                    <h2 className='main-heading-h2 -mt-7 md:-mt-2'>
                        Create New NFT
                    </h2>
                </div>
                <div className='mt-14 sm:mt-10'>
                    <div>
                        <FileUpload
                            label='Upload art'
                            id='nftImage'
                            selectedFile={selectedFile}
                            sImageType={inputValue.sImageType}
                            onChange={fileOnChange}
                            fileType='Image/Video/Audio/GIF/GLB file supported.'
                            error={error.nftImage}
                        />
                    </div>
                    <div className='mt-5'>
                        <Input
                            placeholder='Enter NFT Title'
                            label='NFT Title'
                            id='nftTitle'
                            isRequired={true}
                            error={error.nftTitle}
                            type={'text'}
                            value={inputValue.nftTitle}
                            changeHandler={(e) => {
                                if (e.target.value.length > 25) return;
                                setInputValue((prev) => ({
                                    ...prev,
                                    nftTitle: e.target.value,
                                }));
                            }}
                        />
                    </div>
                    <div className='flex gap-x-5 md:flex-col '>
                        <div className='mt-5 w-1/3 md:w-full'>
                            <Input
                                placeholder=''
                                label='Brand Name'
                                id='brand'
                                value={brand?.sBrandName}
                                disabled={true}
                                type={'text'}
                                changeHandler={(e) => {
                                    setInputValue((prev) => ({
                                        ...prev,
                                        brand: e.target.value,
                                    }));
                                }}
                            />
                        </div>
                        <div className='mt-5 w-1/3 md:w-full'>
                            <SelectDropdown
                                placeholder='Select Category'
                                label='Select Category'
                                id='category'
                                selectOption={categories}
                                selectedOption={
                                    categories?.[inputValue.category]?.sName
                                }
                                setInputValue={setInputValue}
                                isRequired={true}
                                error={error.category}
                            />
                        </div>
                        <div className='mt-5 w-1/3 md:w-full'>
                            <SelectDropdown
                                placeholder='Select Collection'
                                label='Select Collection'
                                id='collection'
                                selectOption={collections}
                                isRequired={true}
                                isCreateCollection={true}
                                selectedOption={
                                    collections?.[inputValue.collection]?.sName
                                }
                                setInputValue={setInputValue}
                                error={error.collection}
                                setIsPopupOpen={setIsAddPopupOpen}
                            />
                        </div>
                    </div>
                    <div className='mt-5'>
                        <TextArea
                            placeholder='Enter Description'
                            label='Description'
                            id='description'
                            isRequired={true}
                            changeHandler={(e) => {
                                if (e.target.value.length > 250) return;
                                setInputValue((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                }));
                            }}
                            error={error.description}
                        />
                    </div>
                    <div className='mt-5'>
                        <div className='float-right cursor-pointer'>
                            <img src={InfoIcon} alt='InfoIcon' />
                        </div>
                        <TextArea
                            placeholder='Add Unlockable Content'
                            label='Add Unlockable Content (Optional)'
                            id='unlockable'
                            changeHandler={(e) => {
                                setInputValue((prev) => ({
                                    ...prev,
                                    unlockable: e.target.value,
                                }));
                            }}
                        />
                    </div>
                    <div className='flex gap-x-5 sm:flex-col'>
                        <div className='mt-5 w-1/2 sm:w-full'>
                            <Input
                                placeholder='Enter Number of Copies'
                                label='Number of Copies'
                                id='quantity'
                                error={error.quantity}
                                type={'number'}
                                isRequired={true}
                                value={inputValue.quantity}
                                changeHandler={(e) => {
                                    setInputValue((prev) => ({
                                        ...prev,
                                        quantity:
                                            e.target.value > 0
                                                ? e.target.value
                                                : '',
                                    }));
                                }}
                            />
                        </div>
                        <div className='mt-5 w-1/2 flex sm:w-full'>
                            <div className='flex-1'>
                                <Input
                                    placeholder='Enter Creator Royalty'
                                    label='Creator Royalty %'
                                    id='royalty'
                                    error={error.royalty}
                                    type={'number'}
                                    isRequired={true}
                                    value={inputValue.royalty}
                                    changeHandler={(e) => {
                                        setInputValue((prev) => ({
                                            ...prev,
                                            royalty:
                                                e.target.value > 0
                                                    ? e.target.value
                                                    : '',
                                        }));
                                    }}
                                />
                            </div>
                            <span className='text-md text-dark-60 font-bold mt-11 mr-4 mb-3 -ml-8'>
                                %
                            </span>
                        </div>
                    </div>
                    <div className='border border-dark-40 p-5 rounded-xl mt-5'>
                        <h6 className='text-lg text-black font-bold'>
                            Properties (Optional)
                        </h6>
                        {propertyFields.map((ele, index) => {
                            return (
                                <div
                                    className='flex gap-x-5 sm:flex-col'
                                    key={index}
                                >
                                    <div className='mt-5 w-1/2 sm:w-full'>
                                        <Input
                                            placeholder='Enter Property Title'
                                            label='Property Title'
                                            id='propertyTitle'
                                            setInputValue={setInputValue}
                                            error={error.propertyTitle[index]}
                                            inputValue={inputValue}
                                            index={index}
                                            value={
                                                inputValue.propertyTitle[index]
                                            }
                                            type={'text'}
                                            changeHandler={(e) => {
                                                setInputValue((prev) => {
                                                    const arr =
                                                        prev.propertyTitle;
                                                    arr[index] = e.target.value;
                                                    return {
                                                        ...prev,
                                                        propertyTitle: arr,
                                                    };
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='mt-5 w-1/2 sm:w-full'>
                                        <div className='flex'>
                                            <div className='flex-1'>
                                                <Input
                                                    placeholder='Enter Property value'
                                                    label='Property Value'
                                                    id='propertyValue'
                                                    type={'number'}
                                                    error={
                                                        error.propertyValue[
                                                        index
                                                        ]
                                                    }
                                                    value={
                                                        inputValue
                                                            .propertyValue[
                                                        index
                                                        ]
                                                    }
                                                    changeHandler={(e) => {
                                                        setInputValue(
                                                            (prev) => {
                                                                const arr =
                                                                    prev.propertyValue;
                                                                arr[index] =
                                                                    e.target.value;

                                                                return {
                                                                    ...prev,
                                                                    propertyValue:
                                                                        arr,
                                                                };
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                            {/* <span className='text-md text-dark-60 font-bold mr-4 mt-11 mb-3 -ml-8'>
                                                %
                                            </span> */}
                                            <div className='ml-5 mt-7'>
                                                <button
                                                    className='group border duration-300 border-black p-4 rounded-xl hover:bg-black sm:p-3'
                                                    onClick={(e) => {
                                                        index
                                                            ? decreaseProperty(
                                                                index
                                                            )
                                                            : increaseProperty();
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            index
                                                                ? MinusIcon
                                                                : PlusIcon
                                                        }
                                                        alt='PlusIcon'
                                                        className='group-hover:invert'
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='border border-dark-40 p-5 rounded-xl mt-5'>
                        <h6 className='text-lg text-black font-bold sm:text-md'>
                            Collaborators (Optional)
                        </h6>
                        {collaboratorsFields.map((ele, index) => {
                            return (
                                <div
                                    className='flex gap-x-5 sm:flex-col'
                                    key={index}
                                >
                                    <div className='mt-5 w-1/2 sm:w-full'>
                                        <SelectDropdown
                                            placeholder='Select Collaborator'
                                            label='Select Collaborator'
                                            id='collaboratorName'
                                            selectOption={collaborators}
                                            selectedOption={
                                                collaborators?.[
                                                    inputValue.collaboratorName[
                                                    index
                                                    ]
                                                ]?.sName
                                            }
                                            index={index}
                                            setInputValue={setInputValue}
                                            inputValue={inputValue}
                                            type={'text'}
                                            error={
                                                error.collaboratorName[index]
                                            }
                                        />
                                    </div>
                                    <div className='mt-5 w-1/2 sm:w-full'>
                                        <div className='flex '>
                                            <div className='flex-1'>
                                                <Input
                                                    placeholder='Enter Collaborator Royalty '
                                                    label='Collaborator Royalty %'
                                                    id='collaboratorValue'
                                                    value={
                                                        inputValue
                                                            .collaboratorValue?.[
                                                        index
                                                        ]
                                                    }
                                                    error={
                                                        error.collaboratorValue[
                                                        index
                                                        ]
                                                    }
                                                    changeHandler={(e) => {
                                                        setInputValue(
                                                            (prev) => {
                                                                const arr =
                                                                    prev.collaboratorValue;
                                                                arr[index] =
                                                                    e.target
                                                                        .value >
                                                                        0
                                                                        ? e
                                                                            .target
                                                                            .value
                                                                        : '';
                                                                return {
                                                                    ...prev,
                                                                    collaboratorValue:
                                                                        arr,
                                                                };
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <span className='text-md text-dark-60 font-bold mt-11 mr-4 mb-3 -ml-8'>
                                                %
                                            </span>
                                            <div className='ml-5 mt-7'>
                                                <button
                                                    className='group border duration-300 border-black p-4 rounded-xl hover:bg-black sm:p-3'
                                                    onClick={(e) => {
                                                        index
                                                            ? decreaseCollaborators(
                                                                index
                                                            )
                                                            : increaseCollaborators();
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            index
                                                                ? MinusIcon
                                                                : PlusIcon
                                                        }
                                                        alt='PlusIcon'
                                                        className='group-hover:invert'
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='border border-dark-40 p-5 rounded-xl mt-5'>
                        <h6 className='text-lg text-black font-bold sm:text-md'>
                            Select Blockchain Network{' '}
                            <span className='text-red-600 ml-0.5'>*</span>
                        </h6>
                        <div className='mt-5'>
                            <RadioButton
                                value={blockchain}
                                onChange={setBlockchain}
                                optionList={blockchainList}
                            />

                            {error.blockChian && (
                                <label className='text-red-600'>
                                    {error.blockChian}
                                </label>
                            )}
                        </div>
                    </div>
                    <div className='text-center mt-10'>
                        <button
                            className='btn-secondary'
                            onClick={handleSubmit}
                            disabled={
                                isPending || isLoading || isLoadingTransaction
                            }
                        >
                            {(isPending ||
                                isLoading ||
                                isLoadingTransaction) && <ButtonLoader />}
                            Add NFT ART
                        </button>
                    </div>
                </div>
            </div>
            <AddCollection
                isPopupOpen={isAddPopupOpen}
                setIsPopupOpen={setIsAddPopupOpen}
            />
        </section>
    );
}

export default CreateNFT;
