const SITE_NAME = "Epiko Market";

const PLATFORM_CONFIG = {
  development: {
    supported_chain_ids: [5, 97],
    supported_chain_names: [
      "Binance Smart Chain - Testnet",
      "Goerli - Testnet",
      "Avalanche fuji - Testnet",
      "Mumbai - Testnet",
    ],
    default_chain_id: 5,
    sIpfsUri: "https://ipfs.io/ipfs/",
    sPinataIpfsUri: "https://epiko.mypinata.cloud/ipfs/",
    sFilebaseIpfsUri: "https://ipfs.filebase.io/ipfs/",
    sS3Location: "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts/",
    sS3ThumbLocation:
      "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts_thumb/",
    network: {
      5: {
        name: "Goerli",
      },
      97: {
        name: "BSC",
      },
      1442: {
        name: "zkEVM",
      },
      80001: {
        name: "Polygon",
      },
      2888: {
        name: "BOBA",
      },
      9728: {
        name: "BOBA BNB",
      },
      84532: {
        name: "Basechain",
      },
    },
    supportedNetworks: [
      {
        name: "Ethereum",
        value: 5,
      },
      {
        name: "BOBA",
        value: 2888,
      },
      {
        name: "BOBA BNB",
        value: 9728,
      },
      {
        name: "Basechain",
        value: 84532,
      },
      {
        name: "Polygon",
        value: 80001,
      },
      {
        name: "zkEVM",
        value: 1442,
      },
      {
        name: "BNB",
        value: 97,
      },
    ],
    supportedTokens: [
      {
        name: "ETH",
        value: "0x0000000000000000000000000000000000000000",
        decimals: 18,
      },
      {
        name: "OMI",
        value: process.env.REACT_APP_OMI_ADDRESS,
        decimals: 18,
      },
      {
        name: "USDC",
        value: process.env.REACT_APP_USDC_ADDRESS,
        decimals: 6,
      },
      {
        name: "SPIZ",
        value: process.env.REACT_APP_SPIZ_ADDRESS,
        decimals: 18,
      },
      {
        name: "EPIKO",
        value: process.env.REACT_APP_EPIKO_ADDRESS,
        decimals: 18,
      },
    ],
    token: {
      USDC: {
        decimals: 6,
        value: process.env.REACT_APP_USDC_ADDRESS,
      },
      OMI: {
        decimals: 18,
        value: process.env.REACT_APP_OMI_ADDRESS,
      },
      SPIZ: {
        decimals: 18,
        value: process.env.REACT_APP_SPIZ_ADDRESS,
      },
      EPIKO: {
        decimals: 18,
        value: process.env.REACT_APP_EPIKO_ADDRESS,
      },
    },
  },
  stage: {
    supported_chain_ids: [5, 97],
    supported_chain_names: [
      "Binance Smart Chain - Testnet",
      "Goerli - Testnet",
      "Avalanche fuji - Testnet",
      "Mumbai - Testnet",
    ],
    default_chain_id: 5,
    sEpikoIpfsUri: "https://epiko.mypinata.cloud/ipfs/",
    sIpfsUri: "https://ipfs.filebase.io/ipfs/",
    sS3Location: "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts/",
    sS3ThumbLocation:
      "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts_thumb/",
    network: {
      5: {
        name: "Goerli",
      },
      97: {
        name: "BSC",
      },
      1442: {
        name: "zkEVM",
      },
      80001: {
        name: "Polygon",
      },
      2888: {
        name: "BOBA",
      },
      9728: {
        name: "BOBA BNB",
      },
      84532: {
        name: "Basechain",
      },
    },
    supportedNetworks: [
      {
        name: "Ethereum",
        value: 5,
      },
      {
        name: "BOBA",
        value: 2888,
      },
      {
        name: "Polygon",
        value: 80001,
      },
      {
        name: "zkEVM",
        value: 1442,
      },
      {
        name: "BNB",
        value: 97,
      },
      {
        name: "BOBA BNB",
        value: 9728,
      },
      {
        name: "Basechain",
        value: 84532,
      },
    ],
    supportedTokens: [
      {
        name: "ETH",
        value: "0x0000000000000000000000000000000000000000",
        decimals: 18,
      },
      {
        name: "OMI",
        value: process.env.REACT_APP_OMI_ADDRESS,
        decimals: 18,
      },
      {
        name: "USDC",
        value: process.env.REACT_APP_USDC_ADDRESS,
        decimals: 6,
      },
      {
        name: "SPIZ",
        value: process.env.REACT_APP_SPIZ_ADDRESS,
        decimals: 18,
      },
      {
        name: "EPIKO",
        value: process.env.REACT_APP_EPIKO_ADDRESS,
        decimals: 18,
      },
    ],
    token: {
      USDC: {
        decimals: 6,
        value: process.env.REACT_APP_USDC_ADDRESS,
      },
      OMI: {
        decimals: 18,
        value: process.env.REACT_APP_OMI_ADDRESS,
      },
      SPIZ: {
        decimals: 18,
        value: process.env.REACT_APP_SPIZ_ADDRESS,
      },
      EPIKO: {
        decimals: 18,
        value: process.env.REACT_APP_EPIKO_ADDRESS,
      },
    },
  },
  testing: {
    supported_chain_ids: [5, 97],
    supported_chain_names: [
      "Binance Smart Chain - Testnet",
      "Goerli - Testnet",
      "Avalanche fuji - Testnet",
      "Mumbai - Testnet",
    ],
    default_chain_id: 5,
    sIpfsUri: "https://ipfs.io/ipfs/",
    sS3Location: "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts/",
    sS3ThumbLocation:
      "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts_thumb/",
    network: {
      5: {
        name: "Goerli",
      },
      97: {
        name: "BSC",
      },
      1442: {
        name: "zkEVM",
      },
      80001: {
        name: "Polygon",
      },
      2888: {
        name: "BOBA",
      },
    },
    supportedNetworks: [
      {
        name: "Ethereum",
        value: 5,
      },
      {
        name: "BOBA",
        value: 2888,
      },
      {
        name: "Polygon",
        value: 80001,
      },
      {
        name: "zkEVM",
        value: 1442,
      },
      {
        name: "BNB",
        value: 97,
      },
    ],
    supportedTokens: [
      {
        name: "ETH",
        value: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        chain: 5,
      },
      {
        name: "OMI",
        value: process.env.REACT_APP_OMI_ADDRESS,
        decimals: 18,
        chain: 5,
      },
      {
        name: "USDC",
        value: process.env.REACT_APP_USDC_ADDRESS,
        decimals: 6,
        chain: 5,
      },
      {
        name: "SPIZ",
        value: process.env.REACT_APP_SPIZ_ADDRESS,
        decimals: 18,
        chain: 5,
      },
      {
        name: "EPIKO",
        value: process.env.REACT_APP_EPIKO_ADDRESS,
        decimals: 18,
        chain: 5,
      },
    ],
    token: {
      USDC: {
        decimals: 6,
        value: process.env.REACT_APP_USDC_ADDRESS,
      },
      OMI: {
        decimals: 18,
        value: process.env.REACT_APP_OMI_ADDRESS,
      },
      SPIZ: {
        decimals: 18,
        value: process.env.REACT_APP_SPIZ_ADDRESS,
      },
      EPIKO: {
        decimals: 18,
        value: process.env.REACT_APP_EPIKO_ADDRESS,
      },
    },
  },
  production: {
    supported_chain_ids: [1, 56, 1101, 137, 288],
    supported_chain_names: [
      "Binance Smart Chain - Testnet",
      "Goerli - Testnet",
      "Avalanche fuji - Testnet",
      "Mumbai - Testnet",
    ],
    default_chain_id: 5,
    sIpfsUri: "https://ipfs.io/ipfs/",
    sPinataIpfsUri: "https://epiko.mypinata.cloud/ipfs/",
    sFilebaseIpfsUri: "https://ipfs.filebase.io/ipfs/",
    sS3Location: "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts/",
    sS3ThumbLocation:
      "https://yudiz-blockchain.s3.amazonaws.com/nft-talent/nfts_thumb/",
    network: {
      1: {
        name: "Ethereum",
      },
      56: {
        name: "BSC",
      },
      1101: {
        name: "zkEVM",
      },
      137: {
        name: "Polygon",
      },
      288: {
        name: "BOBA",
      },
    },
    supportedNetworks: [
      {
        name: "Ethereum",
        value: 1,
      },
      {
        name: "BOBA",
        value: 288,
      },
      {
        name: "Polygon",
        value: 137,
      },
      {
        name: "zkEVM",
        value: 1101,
      },
      {
        name: "BNB",
        value: 56,
      },
    ],
    supportedTokens: [
      {
        name: "ETH",
        value: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        chain: 1,
      },
      {
        name: "OMI",
        value: process.env.REACT_APP_OMI_ADDRESS,
        decimals: 18,
        chain: 1,
      },
      {
        name: "USDC",
        value: process.env.REACT_APP_USDC_ADDRESS,
        decimals: 6,
        chain: 1,
      },
      {
        name: "SPIZ",
        value: process.env.REACT_APP_SPIZ_ADDRESS,
        decimals: 18,
        chain: 1,
      },
      {
        name: "EPIKO",
        value: process.env.REACT_APP_EPIKO_ADDRESS,
        decimals: 18,
        chain: 1,
      },
    ],
    token: {
      USDC: {
        decimals: 6,
        value: process.env.REACT_APP_USDC_ADDRESS,
      },
      OMI: {
        decimals: 18,
        value: process.env.REACT_APP_OMI_ADDRESS,
      },
      SPIZ: {
        decimals: 18,
        value: process.env.REACT_APP_SPIZ_ADDRESS,
      },
      EPIKO: {
        decimals: 18,
        value: process.env.REACT_APP_EPIKO_ADDRESS,
      },
    },
  },
};
export const CONFIG = PLATFORM_CONFIG[process.env.REACT_APP_ENVIRONMENT];
export const SIGN_MSG = `Welcome to ${SITE_NAME}!\n\nClick to sign and accept the ${SITE_NAME}'s Terms of Service.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:`;
